/*
 *  Colors
 */
/*
 *  Resets
 */
a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}

/*
 *  Typography
 */
/*
 *  Mixins
 */
.navigation {
  position: relative;
  display: block;
  height: 100px;
  background-color: transparent;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navigation__logo {
  margin: 20px 0;
  width: 35%;
}

.navigation__menu__item {
  display: inline-block;
}

.navigation__menu__item__link {
  color: white;
  text-decoration: none;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-size: 14px;
  margin: 0 20px;
}

.navigation__menu__item__link__account {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-size: 14px;
  padding: 2px 0;
  padding-left: 28px;
  background: url("/img/account-circle.png") no-repeat center left;
}

.navigation__menu__item--hamburger {
  display: none;
}

.mobile-navigation {
  display: none;
}

.hero {
  background: url("/img/hero.jpg") no-repeat center center;
  background-size: cover;
  height: 520px;
  margin-top: -100px;
  padding-left: 265px;
  padding-top: 200px;
}

.hero__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  color: white;
  margin-bottom: 0;
  width: 460px;
}

.hero__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  width: 420px;
}

.hero__get-started {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: white;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url("/img/arrow_white.png") no-repeat center right;
}

.why__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  margin-top: 45px;
  color: black;
  text-align: center;
}

.why__title--highlight {
  color: #1497EF;
}

.why__cards {
  margin: 40px 180px 0 180px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.why__cards__card {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.why__cards__card__image {
  margin: auto;
  margin-top: 0;
  margin-bottom: 10px;
}

.why__cards__card__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.why__cards__card__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: center;
  margin-top: 0;
}

.manage-team {
  margin-top: 50px;
  height: 834px;
  background: #f4f4fc url("/img/iPad_manageteam.png") no-repeat center right;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.manage-team__left-wrap {
  display: flex;
  justify-content: center;
  flex-grow: 2;
}

.manage-team__left-wrap__copy {
  width: 430px;
  height: 380px;
  margin-top: 150px;
  padding-left: 105px;
  background: url("/img/tab.png") no-repeat top left;
}

.manage-team__left-wrap__copy__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  margin-top: 0;
}

.manage-team__left-wrap__copy__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
}

.manage-team__left-wrap__copy__link {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url("/img/arrow_blue.png") no-repeat center right;
}

.manage-team__right-wrap {
  display: flex;
  flex-grow: 1;
}

.manage-team__right-wrap__floating-card {
  margin-top: 310px;
  background: white;
  width: 330px;
  height: 300px;
  padding: 30px 25px;
  box-sizing: border-box;
  box-shadow: 0 0 4px 4px rgba(128, 128, 128, 0.25);
}

.manage-team__right-wrap__floating-card__branding {
  margin: 5px auto;
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1497EF;
}

.manage-team__right-wrap__floating-card__title {
  font-family: Arial;
  font-weight: normal;
  font-size: 42px;
  line-height: 42px;
  margin: 5px auto;
}

.manage-team__right-wrap__floating-card__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  margin: 25px auto;
}

.take-app {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  background: url("/img/iPhone_mobileready.png") no-repeat 10% center;
  background-size: auto 100%;
  padding: 200px 10%;
}

.take-app__image {
  width: 50%;
}

.take-app__copy {
  width: 50%;
  margin-left: 200px;
}

.take-app__copy__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
}

.take-app__copy__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
}

.take-app__copy__link {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url("/img/arrow_blue.png") no-repeat center right;
}

.connect-team {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.connect-team__copy {
  max-width: 460px;
}

.connect-team__copy__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
}

.connect-team__copy__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
}

.connect-team__copy__link {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url("/img/arrow_blue.png") no-repeat center right;
}

.teammates__upper {
  margin-bottom: 40px;
}

.teammates__lower {
  margin-top: 40px;
}

.testimonials {
  margin: 120px 0;
}

.get-started {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f4f4fc;
  align-items: center;
  padding: 50px 0;
}

.get-started__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
}

.get-started__copy {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  max-width: 620px;
  text-align: center;
  color: #A0B1C2;
  margin-bottom: 0;
}

.get-started__link {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url("/img/arrow_blue.png") no-repeat center right;
  width: 90px;
  margin: 30px 0;
}

.get-started__app-stores {
  margin-bottom: 40px;
}

.get-started__app-stores__logo--appstore, .get-started__app-stores__logo--googleplay {
  margin: 0 15px;
}

.footer {
  margin-top: 0px;
  padding: 80px;
  background-color: #1497EF;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer__navigation__item__link {
  color: white;
  text-decoration: none;
  margin: 30px 0;
  display: block;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.footer__legal__item__link {
  color: white;
  text-decoration: none;
  margin: 30px 0;
  display: block;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.footer__newsletter {
  margin-top: 30px;
  width: 25%;
}

.footer__newsletter__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: white;
  margin-bottom: 30px;
}

.footer__newsletter .inputAddOn {
  display: flex;
}

.footer__newsletter .inputAddOn-field {
  flex: 1;
  border: 1px solid white;
  background-color: transparent;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 6px 12px;
  border-radius: 3px 0 0 3px;
  border-right: none;
  outline: none;
}

.footer__newsletter .inputAddOn-field:focus {
  border: 1px solid white;
  border-right: none;
}

.footer__newsletter .inputAddOn-field::placeholder {
  color: #fff;
}

.footer__newsletter .inputAddOn-item {
  background: url("/img/arrow_forward.png") no-repeat center center;
  width: 32px;
  border: 1px solid white;
  border-left: none;
  border-radius: 0 3px 3px 0;
  appearance: none;
  outline: none;
}

.footer__newsletter .inputAddOn-item:hover {
  background: white url("/img/arrow_forward_blue.png") no-repeat center center;
}

.footer__newsletter .inputAddOn-item:focus {
  outline: none;
}

.footer__newsletter #mce-responses .response {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  display: block;
  margin-top: 20px;
}

.footer__social {
  margin-top: 30px;
}

.footer__social__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: white;
  margin-bottom: 30px;
}

.footer__social__item {
  display: inline-block;
}

.footer__social__item__link--facebook, .footer__social__item__link--twitter, .footer__social__item__link--instagram, .footer__social__item__link--linkedin {
  color: white;
  text-decoration: none;
  margin: 30px 0;
  display: block;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 23px;
  height: 24px;
  margin: 0 5px;
}

.footer__social__item__link--facebook {
  background: url("/img/social.jpg") no-repeat 1px 0;
  margin-left: 0;
}

.footer__social__item__link--twitter {
  background: url("/img/social.jpg") no-repeat -22px 0px;
}

.footer__social__item__link--instagram {
  background: url("/img/social.jpg") no-repeat -44px 0px;
}

.footer__social__item__link--linkedin {
  background: url("/img/social.jpg") no-repeat -66px 0px;
}

/*
 * Flipster
 */
.flipster--loop .flipster__item {
  position: absolute;
}

.flipster--loop .flipster__item--past-2 {
  transform: translateX(-100%);
}

.flipster--loop .flipster__item--future-2 {
  transform: translateX(100%);
}

.flipster--loop .flipster__item--past-1 {
  transform: translateX(-50%);
}

.flipster--loop .flipster__item--future-1 {
  transform: translateX(50%);
}

.flipster--infinite-carousel .flipster__container,
.flipster--infinite-carousel .flipster__item {
  transition: all 350ms ease-in-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}

.flipster--infinite-carousel .flipster__item__content {
  transition: inherit;
}

.flipster--infinite-carousel .flipster__item {
  position: absolute;
  opacity: 0;
  perspective: 800px;
}

.flipster--infinite-carousel .flipster__item--past-2,
.flipster--infinite-carousel .flipster__item--future-2 {
  opacity: 0.6;
  transition-delay: 90ms;
}

.flipster--infinite-carousel .flipster__item--past-1,
.flipster--infinite-carousel .flipster__item--future-1 {
  opacity: 0.8;
  transition-delay: 60ms;
}

.flipster--infinite-carousel .flipster__item--current {
  opacity: 1;
  transition-delay: 0;
}

.flipster--infinite-carousel .flipster__item--past .flipster__item__content,
.flipster--infinite-carousel .flipster__item--future .flipster__item__content {
  transform: scale(0.4);
  opacity: 0.7;
}

.flipster--infinite-carousel .flipster__item--past-2 .flipster__item__content {
  transform: translateX(-60%) scale(0.8);
}

.flipster--infinite-carousel .flipster__item--future-2 .flipster__item__content {
  transform: translateX(-60%) scale(0.8);
}

.flipster--infinite-carousel .flipster__item--past-1 .flipster__item__content {
  transform: translateX(-60%) scale(0.8);
}

.flipster--infinite-carousel .flipster__item--future-1 .flipster__item__content {
  transform: translateX(60%) scale(0.8);
}

.flipster--infinite-carousel .flipster__item--current .flipster__item__content {
  transform: translateX(0) rotateY(0deg) scale(1);
}

.flipster:focus {
  outline: none;
}

.controls {
  text-align: center;
  margin-top: 40px;
}

.next {
  display: inline-block;
  width: 41px;
  height: 41px;
  background: url("/img/arrow_right.png");
  cursor: pointer;
}

.prev {
  display: inline-block;
  width: 41px;
  height: 41px;
  margin-right: 20px;
  background: url("/img/arrow_left.png");
  cursor: pointer;
}

@media only screen and (min-width: 1700px) {
  .manage-team__right-wrap__floating-card {
    width: 40%;
    height: 250px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1200px) {
  .navigation__logo {
    width: 75%;
  }
  .navigation__logo__image {
    margin-left: 5%;
    z-index: 300;
  }
  .navigation__menu__item {
    display: none;
  }
  .navigation__menu__item__link--hamburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 64px;
    height: 64px;
    font-size: 0;
    text-indent: -9999px;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.25s;
    background: transparent;
  }
  .navigation__menu__item__link--hamburger:focus {
    outline: none;
  }
  .navigation__menu__item__link--hamburger span {
    display: block;
    position: absolute;
    top: 30px;
    left: 14px;
    right: 14px;
    height: 3px;
    background: white;
  }
  .navigation__menu__item__link--hamburger span::before, .navigation__menu__item__link--hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    content: "";
    transition-duration: 0.25s, 0.25s;
    transition-delay: 0.25s, 0s;
  }
  .navigation__menu__item__link--hamburger span::before {
    top: -10px;
    transition-property: top, transform;
  }
  .navigation__menu__item__link--hamburger span::after {
    bottom: -10px;
    transition-property: bottom, transform;
  }
  .navigation__menu__item__link--hamburger.is-active span {
    background: none;
  }
  .navigation__menu__item__link--hamburger.is-active span::before, .navigation__menu__item__link--hamburger.is-active span::after {
    transition-delay: 0s, 0.25s;
  }
  .navigation__menu__item__link--hamburger.is-active span::before {
    top: 0;
    transform: rotate(45deg);
  }
  .navigation__menu__item__link--hamburger.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
  }
  .navigation__menu__item--hamburger {
    display: block;
  }
  .mobile-navigation {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    background: linear-gradient(#45B4FF, #1497EF);
  }
  .mobile-navigation__menu {
    margin-top: 80px;
    padding: 0 5%;
  }
  .mobile-navigation__menu__item__link {
    color: white;
    text-decoration: none;
    font-family: Arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-size: 14px;
    margin: 0 20px;
    font-size: 1.2em;
    line-height: 3em;
  }
  .mobile-navigation__menu__item:first-of-type {
    font-size: 1em;
    line-height: 3em;
    margin-bottom: 10px;
    border-bottom: 1px solid white;
  }
  .mobile-navigation.active {
    opacity: 1.0;
    display: block;
    animation: fadeInFromNone 0.75s;
    z-index: 900;
    overflow: hidden;
  }
  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
    10% {
      display: block;
      opacity: 0.5;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
}

/*
 *  Media Queries
 */
@media only screen and (max-width: 1600px) {
  .hero {
    padding-left: 10%;
  }
}

@media only screen and (min-width: 1070px) and (max-width: 1200px) {
  .manage-team {
    background: #f4f4fc url("/img/iPad_manageteam.png") no-repeat 120% center;
    background-size: 50%;
  }
  .take-app {
    background: url("/img/iPhone_mobileready.png") no-repeat 10% center;
    background-size: auto 80%;
    padding: 200px 10%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .manage-team {
    background: #f4f4fc url("/img/iPad_manageteam.png") no-repeat 120% center;
  }
  .manage-team__left-wrap__copy {
    padding-left: 10%;
    width: 320px;
  }
}

@media only screen and (min-width: 1070px) and (max-width: 1600px) {
  .why__cards {
    margin: 40px 80px;
  }
  .why__cards__card {
    width: 250px;
  }
}

@media only screen and (max-width: 1070px) {
  .manage-team {
    background: #f4f4fc url("/img/iPad_manageteam_mobile.png") no-repeat right 90%;
    height: 900px;
  }
  .manage-team__left-wrap {
    width: 100%;
  }
  .manage-team__left-wrap__copy {
    margin-top: 50px;
    padding-left: 0;
    background: none;
    width: 75%;
  }
  .manage-team__right-wrap {
    display: none;
  }
  .take-app {
    background: url("/img/iPhone_mobileready.png") no-repeat 10% center;
    background-size: auto 80%;
    padding: 100px 10%;
  }
  .connect-team__copy {
    width: 40%;
  }
  .teammates__upper__person {
    display: none;
  }
  .teammates__lower__person {
    display: none;
  }
  .footer__newsletter {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .hero {
    padding-left: 10%;
    padding-top: 120px;
    background: url("/img/hero_mobile.jpg") no-repeat center bottom;
    background-size: cover;
  }
  .hero__title {
    font-size: 34px;
    width: 400px;
    max-width: 100%;
  }
  .hero__text {
    width: 310px;
    max-width: 100%;
  }
  .why__title {
    font-size: 38px;
  }
  .why__cards {
    margin: 40px auto;
  }
  .manage-team {
    height: 900px;
    background: #f4f4fc url("/img/iPad_manageteam_mobile.png") no-repeat right 90%;
    background-size: auto 300px;
  }
  .manage-team__left-wrap__copy__title {
    font-size: 38px;
  }
  .take-app {
    background: url("/img/iPhone_mobileready.png") no-repeat center bottom;
    background-size: auto 50%;
    height: 800px;
    margin-top: 0;
    padding: 50px 10%;
  }
  .take-app__image {
    display: none;
  }
  .take-app__copy {
    width: 100%;
    margin: 0;
  }
  .take-app__copy__title {
    font-size: 38px;
  }
  .connect-team {
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .connect-team__copy {
    width: 80%;
    max-width: none;
    margin-bottom: 40px;
  }
  .connect-team__copy__title {
    font-size: 38px;
  }
  .teammates {
    width: 80%;
  }
  .teammates__lower {
    text-align: right;
  }
  .get-started__title {
    font-size: 38px;
  }
  .get-started__copy {
    max-width: 80%;
  }
  .get-started__app-stores__logo--appstore, .get-started__app-stores__logo--googleplay {
    width: 40%;
  }
  .footer {
    justify-content: space-between;
    padding: 40px;
  }
  .footer__navigation, .footer__legal {
    width: 50%;
  }
  .flipster .flipster__container {
    max-width: 90%;
    margin: auto;
  }
  .flipster--infinite-carousel .flipster__item--past-2 .flipster__item__content {
    transform: translateX(0) scale(0.3);
  }
  .flipster--infinite-carousel .flipster__item--future-2 .flipster__item__content {
    transform: translateX(0) scale(0.3);
  }
  .flipster--infinite-carousel .flipster__item--past-1 .flipster__item__content {
    transform: translateX(0) scale(0.3);
  }
  .flipster--infinite-carousel .flipster__item--future-1 .flipster__item__content {
    transform: translateX(0) scale(0.3);
  }
  .flipster__item__content img {
    width: 360px;
  }
}

.features-hero {
  background: linear-gradient(#45B4FF, #1497EF);
  height: 280px;
  margin-top: -100px;
  padding-left: 265px;
  padding-top: 100px;
}

.features-hero__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  color: white;
  margin-bottom: 0;
}

.features-hero__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  width: 420px;
}

.features-copy {
  margin-bottom: 80px;
}

.features-copy__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  text-align: center;
}

.features-copy__intro {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: center;
}

.major-features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.major-features__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 40%;
}

.major-features__copy p {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: justify;
  margin-bottom: 40px;
  width: 60%;
}

.major-features__copy p strong {
  display: block;
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin: 20px 0;
}

.major-features__image {
  width: 60%;
  margin: auto;
}

.tracking-features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tracking-features__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 40%;
  order: 2;
}

.tracking-features__copy p {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: justify;
  margin-bottom: 40px;
  width: 60%;
}

.tracking-features__copy p strong {
  display: block;
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin: 20px 0;
}

.tracking-features__image {
  display: flex;
  order: 1;
  width: 60%;
}

.tracking-features__image img {
  max-width: 760px;
  height: auto;
}

.applications {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.applications__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 40%;
}

.applications__copy p {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: justify;
  margin-bottom: 40px;
  width: 60%;
}

.applications__copy p strong {
  display: block;
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin: 20px 0;
}

.applications__image {
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 1070px) {
  .features-hero {
    padding-left: 25%;
  }
  .major-features__copy {
    width: 100%;
  }
  .major-features__image {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
  .major-features__image img {
    width: 95%;
  }
  .tracking-features__copy {
    width: 100%;
    order: 1;
  }
  .tracking-features__image {
    width: 100%;
    order: 2;
  }
  .tracking-features__image img {
    width: 100%;
    height: 100%;
  }
  .applications__copy {
    width: 100%;
  }
  .applications__image {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
  .applications__image img {
    min-height: 400px;
    max-height: 750px;
  }
}

@media only screen and (max-width: 800px) {
  .features-hero {
    padding-left: 10%;
    height: 300px;
  }
  .features-hero__text {
    width: 75%;
  }
  .features-copy__title {
    font-size: 38px;
    width: 80%;
    margin: auto;
    margin-top: 80px;
  }
  .features-copy__intro {
    width: 60%;
    margin: auto;
  }
  .major-features__copy p {
    width: 80%;
  }
  .tracking-features {
    margin-top: 80px;
  }
  .tracking-features__copy p {
    width: 80%;
  }
  .applications__copy p {
    width: 80%;
  }
  .applications__image img {
    min-height: 400px;
    max-height: 500px;
  }
}

.pricing-hero {
  background: linear-gradient(#45B4FF, #1497EF);
  height: 280px;
  margin-top: -100px;
  padding-top: 100px;
}

.pricing-hero__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  color: white;
  text-align: center;
}

.pricing-hero__text {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  width: 420px;
  text-align: center;
  margin: auto;
}

.pricing-copy {
  margin-bottom: 80px;
}

.pricing-copy__title {
  font-family: Arial;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  text-align: center;
}

.pricing-copy__intro {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  text-align: center;
}

.pricing-controls {
  display: none;
  text-align: center;
  margin-top: 60px;
}

.pricing-controls__button {
  width: 200px;
  height: 50px;
  background: white;
  border: 2px solid #f4f4fc;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
}

.pricing-controls__button--pro {
  border-right: none;
}

.pricing-controls__button:focus {
  background-color: #f4f4fc;
  outline: none;
}

.pricing-table__table {
  border: 1px solid #f4f4fc;
  width: 1000px;
  margin: auto;
  margin-top: 80px;
  border-spacing: 0;
}

.pricing-table__table__head {
  background: #f4f4fc;
}

.pricing-table__table__head th {
  padding: 30px;
  text-align: center;
  font-family: Arial;
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
}

.pricing-table__table__head--items {
  width: 40%;
}

.pricing-table__table__head--pro, .pricing-table__table__head--enterprise {
  width: 30%;
}

.pricing-table__table__head--pro-sm, .pricing-table__table__head--enterprise-sm {
  display: none;
}

.pricing-table__table__body {
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
}

.pricing-table__table__body__row--pro-price, .pricing-table__table__body__row--enterprise-price {
  font-family: Arial;
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
  color: black;
  padding: 20px 0 !important;
}

.pricing-table__table__body__row--pro-price sup, .pricing-table__table__body__row--enterprise-price sup {
  font-size: 18px;
}

.pricing-table__table__body__row--pro-price .strikethrough {
  position: relative;
}

.pricing-table__table__body__row--pro-price .strikethrough:before {
  position: absolute;
  content: "";
  left: -20px;
  top: 50%;
  right: -25px;
  border-top: 2px solid;
  border-color: red;
  transform: rotate(-20deg);
}

.pricing-table__table__body__row--pro-price small {
  display: block;
  font-family: Arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #A0B1C2;
  margin-top: 10px;
}

.pricing-table__table__body td {
  padding: 10px 0;
  border: 1px solid #f4f4fc;
}

.pricing-table__table__body td:first-child {
  padding-left: 40px;
}

.pricing-table__table__body td:not(:first-child) {
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .pricing-controls {
    display: block;
  }
  .pricing-controls__button {
    cursor: pointer;
  }
  .pricing-controls__button--pro {
    background: #f4f4fc;
  }
  .pricing-table__table__head--items, .pricing-table__table__head--pro, .pricing-table__table__head--enterprise {
    display: none;
  }
  .pricing-table__table__head--pro-sm {
    display: table-cell;
  }
  .pricing-table__table__body__row--enterprise-price, .pricing-table__table__body__row--enterprise-column {
    display: none;
  }
  .pricing-table__table__body__row--pro-price, .pricing-table__table__body__row--enterprise-price {
    width: 160px;
  }
  .pricing-table__table__body__row td {
    padding-left: 10px;
  }
  .pricing-table__table__body__row td:first-child {
    padding-left: 10px;
    width: 220px;
  }
}

@media only screen and (max-width: 1070px) {
  .pricing-table__table {
    width: auto;
  }
}
