// Mobile "hamburger" menu

@media only screen and (max-width: 1200px) {

  .navigation {

    &__logo {
      width: 75%;
      &__image {
        margin-left: 5%;
        z-index: 300;
      }
    }

    &__menu {
      &__item {
        display: none;
        &__link {
          &--hamburger {

            // Animated button

            display: block;
            position: relative;
            overflow: hidden;
            margin: 0;
            padding: 0;
            width: 64px;
            height: 64px;
            font-size: 0;
            text-indent: -9999px;
            box-shadow: none;
            border-radius: none;
            border: none;
            cursor: pointer;
            transition: background 0.25s;
            background: transparent;

            &:focus {
              outline: none;
            }

            span {
              display: block;
              position: absolute;
              top: 30px;
              left: 14px;
              right: 14px;
              height: 3px;
              background: white;

              &::before, &::after {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: #fff;
                content: "";

                transition-duration: 0.25s, 0.25s;
                transition-delay: 0.25s, 0s;
              }

              &::before {
                top: -10px;

                transition-property: top, transform;
              }

              &::after {
                bottom: -10px;
                transition-property: bottom, transform;
              }

            }

            &.is-active {
              span {
                background: none;

                &::before, &::after {
                  transition-delay: 0s, 0.25s;
                }

                &::before {
                  top: 0;
                  transform: rotate(45deg);
                }

                &::after {
                  bottom: 0;
                  transform: rotate(-45deg);
                }
              }
            }

          }
        }

        &--hamburger {
          display: block;
        }

      }
    }
  }


  .mobile-navigation {

    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top:  0;
    left: 0;
    display: none;

    background: linear-gradient($ca-light-blue, $ca-blue);

    &__menu {

      margin-top: 80px;
      padding: 0 5%;

      &__item {

        &__link {
          @include navigation_link;
          font-size: 1.2em;
          line-height: 3em;
        }

        &:first-of-type {
          font-size: 1em;
          line-height: 3em;
          margin-bottom: 10px;
          border-bottom: 1px solid white;
        }
      }
    }

    // Fade In animation
    &.active {
      opacity: 1.0;
      display: block;
      animation: fadeInFromNone 0.75s;
      z-index: 900;
      overflow: hidden;
    }

    @keyframes fadeInFromNone {
      0% {
        display: none;
        opacity: 0;
      }

      10% {
        display: block;
        opacity: 0.5;
      }

      100% {
        display: block;
        opacity: 1;
      }
    }


  }

}



/*
 *  Media Queries
 */

@media only screen
and (max-width: 1600px){
  .hero {
    padding-left: 10%;
  }
}


@media only screen
and (min-width: 1070px)
and (max-width: 1200px){

  .manage-team {
    background: #f4f4fc url('/img/iPad_manageteam.png') no-repeat 120% center;
    background-size: 50%;
  }

  .take-app {
    background: url('/img/iPhone_mobileready.png') no-repeat 10% center;
    background-size: auto 80%;
    padding: 200px 10%;
  }

}



@media only screen
and (min-width: 1200px)
and (max-width: 1600px) {

  .manage-team {
    background: #f4f4fc url('/img/iPad_manageteam.png') no-repeat 120% center;
    &__left-wrap {
      &__copy {
        padding-left: 10%;
        width: 320px;
      }
    }
  }


}



@media only screen
and (min-width: 1070px)
and (max-width: 1600px) {

  .why {
    &__cards {
      margin: 40px 80px;
      &__card{
        width: 250px;
      }

    }

  }

}


@media only screen
and (max-width: 1070px){

  .manage-team {
    background: #f4f4fc url('/img/iPad_manageteam_mobile.png') no-repeat right 90%;
    height: 900px;

    &__left-wrap {
      width: 100%;
      &__copy {
        margin-top: 50px;
        padding-left: 0;
        background: none;
        width: 75%;
      }
    }

    &__right-wrap {
      display: none;
    }
  }


  .take-app {
    background: url('/img/iPhone_mobileready.png') no-repeat 10% center;
    background-size: auto 80%;
    padding: 100px 10%;
  }

  .connect-team {
    &__copy{
      width: 40%;
    }
  }

  .teammates{

    &__upper{
      &__person{
        display: none;
      }
    }

    &__lower{
      &__person{
        display: none;
      }
    }

  }

  .footer{
    &__newsletter{
      display: none;
    }
  }


}


@media only screen
and (max-width: 800px){

  .hero {
    padding-left: 10%;
    padding-top: 120px;
    background: url('/img/hero_mobile.jpg') no-repeat center bottom;
    background-size: cover;

    &__title{
      font-size: 34px;
      width: 400px;
      max-width: 100%;
    }

    &__text{
      width: 310px;
      max-width: 100%;
    }
  }


  .why {
    &__title {
      font-size: 38px;
    }
    &__cards {
      margin: 40px auto;
    }
  }


  .manage-team {
    height: 900px;
    background: #f4f4fc url('/img/iPad_manageteam_mobile.png') no-repeat right 90%;
    background-size: auto 300px;

    &__left-wrap {
      &__copy {
        &__title{
          font-size: 38px;
        }
      }
    }

  }


  .take-app {
    background: url('/img/iPhone_mobileready.png') no-repeat center bottom;
    background-size: auto 50%;
    height: 800px;
    margin-top: 0;
    padding: 50px 10%;

    &__image {
      display: none;
    }

    &__copy {
      width: 100%;
      margin: 0;
      &__title {
        font-size: 38px;
      }
    }
  }

  .connect-team {
    flex-wrap: wrap;
    margin-top: 40px;
    &__copy {
      width: 80%;
      max-width: none;
      margin-bottom: 40px;
      &__title{
        font-size: 38px;
      }
    }
  }

  .teammates {
    width: 80%;

    &__lower {
      text-align: right;
    }
  }


  .get-started {
    &__title{
      font-size: 38px;
    }
    &__copy {
      max-width: 80%;
    }
    &__app-stores {
      &__logo {
        &--appstore, &--googleplay{
          width: 40%;
        }
      }
    }
  }


  .footer {
    justify-content: space-between;
    padding: 40px;
    &__navigation, &__legal {
      width: 50%;
    }
  }


  .flipster {
     .flipster__container{
        max-width: 90%;
       margin: auto;
     }

    &--infinite-carousel {
      .flipster__item--past-2 .flipster__item__content { transform: translateX(0)  scale(0.3); }
      .flipster__item--future-2 .flipster__item__content { transform: translateX(0) scale(0.3); }
      .flipster__item--past-1 .flipster__item__content { transform: translateX(0) scale(0.3); }
      .flipster__item--future-1 .flipster__item__content { transform: translateX(0) scale(0.3); }
    }

    &__item{
      &__content{
        img {
          width: 360px;
        }
      }
    }

  }


}
