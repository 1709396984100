

.features-hero {
  background: linear-gradient($ca-light-blue, $ca-blue);
  height: 280px;
  margin-top: -100px;
  padding-left: 265px;
  padding-top: 100px;
  &__title {
    @include ca-h1;
    color: white;
    margin-bottom: 0;
  }
  &__text {
    @include ca-p1;
    color: white;
    width: 420px;
  }
}

.features-copy {

  margin-bottom: 80px;

  &__title{
    @include ca-h1;
    text-align: center;
  }

  &__intro{
    @include ca-p1;
    color: $ca-gray;
    text-align: center;
  }

}


@mixin features_p {
  p {
    @include ca-p1;
    color: $ca-gray;
    text-align: justify;
    margin-bottom: 40px;
    width: 60%;
    strong {
      display: block;
      @include ca-p2;
      color: black;
      margin: 20px 0;;
    }
  }
}


.major-features {

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include features_p;
    margin:auto;
    width: 40%;
  }

  &__image {
    width: 60%;
    margin: auto;
  }

}


.tracking-features{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include features_p;
    margin:auto;
    width: 40%;
    order: 2;
  }

  &__image{
    display: flex;
    order: 1;
    width:60%;
    img {
      max-width: 760px;
      height: auto;
    }
  }
}


.applications{

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include features_p;
    margin:auto;
    width: 40%;
  }

  &__image {
    width: 60%;
    margin: auto;
  }

}




@media only screen
and(max-width: 1070px) {


  .features-hero {
    padding-left: 25%;
  }

  .major-features{
    &__copy {
      width: 100%;
    }
    &__image {
      margin-top: 40px;
      width: 100%;
      text-align: center;
      img {
        width: 95%;
      }
    }

  }

  .tracking-features {

    &__copy{
      width: 100%;
      order: 1;
    }

    &__image{
      width: 100%;
      order: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .applications{
    &__copy {
      width: 100%;
    }
    &__image {
      margin-top: 40px;
      width: 100%;
      text-align: center;
      img {
        min-height: 400px;
        max-height: 750px;
      }
    }

  }

}



@media only screen
and(max-width: 800px) {

  .features-hero {
    padding-left: 10%;
    height: 300px;
    &__text{
      width: 75%;
    }
  }

  .features-copy {
    &__title {
      font-size: 38px;
      width: 80%;
      margin: auto;
      margin-top: 80px;
    }
    &__intro {
      width: 60%;
      margin: auto;
    }
  }

  .major-features {
    &__copy {
      p {
        width: 80%;
      }
    }
  }

  .tracking-features {
    margin-top: 80px;
    &__copy {
      p {
        width: 80%;
      }
    }

  }

  .applications {
    &__copy {
      p {
        width: 80%;
      }
    }

    &__image {
      img {
        min-height: 400px;
        max-height: 500px;
      }
    }
  }

}
