.pricing-hero {

  background: linear-gradient($ca-light-blue, $ca-blue);
  height: 280px;
  margin-top: -100px;
  padding-top: 100px;

  &__title {
    @include ca-h1;
    color: white;
    text-align: center;
  }

  &__text {
    @include ca-p1;
    color: white;
    width: 420px;
    text-align: center;
    margin: auto;
  }

}


.pricing-copy {

  margin-bottom: 80px;

  &__title {
    @include ca-h1;
    text-align: center;
  }

  &__intro {
    @include ca-p1;
    color: $ca-gray;
    text-align: center;
  }

}


.pricing-controls {
  display: none;
  text-align: center;
  margin-top:60px;
  &__button {
    width: 200px;
    height: 50px;
    background: white;
    border: 2px solid $ca-light-gray;
    @include ca-p1;
    color: $ca-gray;
    &--pro {
      border-right: none;
    }
    &:focus {
      background-color: $ca-light-gray;
      outline: none;
    }
  }
}


.pricing-table {
  &__table {
    border: 1px solid $ca-light-gray;
    width: 1000px;
    margin: auto;
    margin-top: 80px;
    border-spacing: 0;

    &__head {
      background: $ca-light-gray;
      th {
        padding: 30px;
        text-align: center;
        @include ca-h3;
      }

      &--items {
        width: 40%;
      }

      &--pro, &--enterprise{
        width: 30%;
      }

      &--pro-sm, &--enterprise-sm{
        display: none;
      }

    }

    &__body {
      @include ca-p1;
      color: $ca-gray;

      &__row {
        &--pro-price, &--enterprise-price {
          @include ca-h3;
          color: black;
          padding: 20px 0!important;
          sup {
            font-size: 18px;
          }
        }

        &--pro-price {

          .strikethrough {
            position: relative;

            &:before {
              position: absolute;
              content: "";
              left: -20px;
              top: 50%;
              right: -25px;
              border-top: 2px solid;
              border-color: red;
              transform:rotate(-20deg);
            }
          }

          small {
            display: block;
            @include ca-p1;
            color: $ca-gray;
            margin-top: 10px;
          }
        }

      }

      td {
        padding: 10px 0;
        border: 1px solid $ca-light-gray;
      }

      td:first-child {
        padding-left: 40px;
      }

      td:not(:first-child) {
        text-align: center;
      }

    }
  }

}


@media only screen
and(max-width: 800px) {

  .pricing-controls {
    display: block;
    &__button {
      cursor: pointer;
      &--pro {
        background: $ca-light-gray;
      }
    }
  }

  .pricing-table {
    &__table {
      &__head {
        &--items, &--pro, &--enterprise {
          display: none;
        }
        &--pro-sm {
          display: table-cell;
        }
      }
      &__body {
        &__row {
          &--enterprise-price, &--enterprise-column {
            display: none;
          }
          &--pro-price, &--enterprise-price {
            width: 160px;
          }
          & td {
            padding-left: 10px;
            &:first-child {
              padding-left: 10px;
              width: 220px;
            }
          }
        }
      }
    }
  }

}


@media only screen
and(max-width: 1070px) {

  .pricing-table {
    &__table {
      width: auto;
    }
  }

}
