/*
 *  Colors
 */

$ca-blue: #1497EF;
$ca-light-blue: #45B4FF;
$ca-gray: #A0B1C2;
$ca-light-gray: #f4f4fc;

/*
 *  Resets
 */

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}


/*
 *  Typography
 */

@mixin font_bold {
  font-family: Arial;
  font-weight: bold;
}

@mixin font_regular {
  font-family: Arial;
  font-weight: normal;
}


/*
 *  Mixins
 */

@mixin ca-h1 {
  @include font_bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
}

@mixin ca-h2 {
  @include font_regular;
  font-size: 42px;
  line-height: 42px;
}

@mixin ca-h3 {
  @include font_bold;
  font-size: 32px;
  line-height: 24px;
}

@mixin ca-p1 {
  @include font_regular;
  font-size: 16px;
  line-height: 24px;
}

@mixin ca-p2 {
  @include font_bold;
  font-size: 16px;
  line-height: 24px;
}

@mixin arrowlink($text_color, $arrow_color) {
  @include ca-p2;
  color: $text_color;
  display: block;
  width: 120px;
  padding: 6px 0;
  background: url('/img/arrow_#{$arrow_color}.png') no-repeat center right;
}

@mixin navigation_link{
  color: white;
  text-decoration:none;
  @include ca-p1();
  font-size: 14px;
  margin: 0 20px;
}

.navigation {
  position: relative;
  display: block;
  height:100px;
  background-color: transparent;
  z-index: 1000;

  display: flex;
  justify-content: space-around;
  align-items: center;

  &__logo {
    margin: 20px 0;
    width: 35%;
  }

  &__menu{
    &__item{
      display: inline-block;
      &__link {
        @include navigation_link;
        &__account{
          @include ca-p2;
          font-size: 14px;
          padding: 2px 0;
          padding-left: 28px;
          background: url('/img/account-circle.png') no-repeat center left;
        }
      }
      &--hamburger {
        display: none;
      }
    }
  }

}

.mobile-navigation{
  display: none;
}

.hero {
  background: url('/img/hero.jpg') no-repeat center center;
  background-size: cover;
  height: 520px;
  margin-top: -100px;
  padding-left: 265px;
  padding-top: 200px;
  &__title {
    @include ca-h1;
    color: white;
    margin-bottom: 0;
    width: 460px;
  }
  &__text {
    @include ca-p1;
    color: white;
    width: 420px;
  }
  &__get-started {
    @include arrowlink(white, white);
  }
}


.why {
  &__title {
    @include ca-h1;
    margin-top: 45px;
    color: black;
    text-align: center;
    &--highlight {
      color: $ca-blue;
    }
  }

  &__cards {

    margin: 40px 180px 0 180px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &__card {
      width: 350px;
      display: flex;
      flex-direction: column;

      &__image {
        margin: auto;
        margin-top: 0;
        margin-bottom: 10px;
      }

      &__title {
        @include ca-p2;
        text-align: center;
      }

      &__text {
        @include ca-p1;
        color: $ca-gray;
        text-align: center;
        margin-top: 0;
      }
    }
  }
}


.manage-team {
  margin-top: 50px;
  height: 834px;
  background: #f4f4fc url('/img/iPad_manageteam.png') no-repeat center right;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__left-wrap {
    display: flex;
    justify-content: center;
    flex-grow: 2;

    &__copy {
      width: 430px;
      height: 380px;
      margin-top: 150px;
      padding-left: 105px;
      background: url('/img/tab.png') no-repeat top left;
      &__title {
        @include ca-h1;
        margin-top: 0;
      }
      &__text {
        @include ca-p1;
        color: $ca-gray;
      }
      &__link {
        @include arrowlink(black, blue)
      }
    }

  }

  &__right-wrap {

    display: flex;
    flex-grow: 1;

    &__floating-card {
      margin-top: 310px;
      background: white;
      width: 330px;
      height: 300px;
      padding: 30px 25px;
      box-sizing: border-box;
      box-shadow: 0 0 4px 4px rgba(128, 128, 128, 0.25);

      &__branding {
        margin: 5px auto;
        @include ca-p2;
        color: $ca-blue;
      }

      &__title {
        @include ca-h2;
        margin: 5px auto;
      }

      &__text {
        @include ca-p1;
        color: $ca-gray;
        margin: 25px auto;
      }
    }
  }
}


.take-app {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  background: url('/img/iPhone_mobileready.png') no-repeat 10% center;
  background-size: auto 100%;
  padding: 200px 10%;
  &__image{
    width: 50%;
  }
  &__copy {
    width: 50%;
    margin-left: 200px;
    &__title {
      @include ca-h1();
    }
    &__text {
      @include ca-p1();
      color: $ca-gray;
    }
    &__link {
      @include arrowlink(black, blue);
    }
  }
}


.connect-team {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &__copy {
    max-width: 460px;
    &__title {
      @include ca-h1();
    }
    &__text {
      @include ca-p1();
      color: $ca-gray;
    }
    &__link {
      @include arrowlink(black,blue);
    }
  }
}


.teammates{
  &__upper{
    margin-bottom: 40px;
  }
  &__lower{
    margin-top: 40px;
  }
}


.testimonials {
  margin: 120px 0;
}


.get-started {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f4f4fc;
  align-items: center;
  padding: 50px 0;
  &__title {
    @include ca-h1();
  }
  &__copy {
    @include ca-p1();
    max-width: 620px;
    text-align: center;
    color: $ca-gray;
    margin-bottom: 0;
  }
  &__link {
    @include arrowlink(black,blue);
    width: 90px;
    margin: 30px 0;
  }
  &__app-stores {
    margin-bottom: 40px;
    &__logo {
      &--appstore,
      &--googleplay {
        margin: 0 15px;
      }
    }
  }
}


.footer {
  margin-top: 0px;
  padding: 80px;
  background-color: $ca-blue;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @mixin footer_link {
    color: white;
    text-decoration:none;
    margin: 30px 0;
    display: block;
    @include ca-p1();
  }

  &__navigation{
    &__item {
      &__link {
        @include footer_link();
      }
    }
  }

  &__legal {
    &__item {
      &__link {
        @include footer_link();
      }
    }
  }

  // Newsletter

  &__newsletter {
    margin-top: 30px;
    width: 25%;
    &__title {
      @include ca-p2();
      display: block;
      color: white;
      margin-bottom: 30px;
    }


    .inputAddOn {
      display: flex;

      &-field {
        flex: 1;
        border: 1px solid white;
        background-color: transparent;
        @include ca-p1();
        color: white;
        padding: 6px 12px;
        border-radius: 3px 0 0 3px;
        border-right: none;
        outline: none;

        &:focus {
          border: 1px solid white;
          border-right: none;
        }

        &::placeholder{
          color: #fff;
        }

      }

      &-item {
        background: url('/img/arrow_forward.png') no-repeat center center;
        width: 32px;
        border: 1px solid white;
        border-left: none;
        border-radius: 0 3px 3px 0;
        appearance: none;
        outline: none;
        &:hover {
          background: white url('/img/arrow_forward_blue.png') no-repeat center center;
        }
        &:focus {
          outline: none;
        }
      }

    }

    #mce-responses .response {
      @include ca-p1();
      color: white;
      display: block;
      margin-top: 20px;
    }

  }

  // --------------------

  &__social{
    margin-top: 30px;

    &__title{
      @include ca-p2();
      display: block;
      color: white;
      margin-bottom: 30px;
    }

    &__item {

      display: inline-block;

      &__link {
        &--facebook, &--twitter, &--instagram, &--linkedin{
          @include footer_link();
          width: 23px;
          height: 24px;
          margin: 0 5px;
        }
        &--facebook{
          background: url('/img/social.jpg') no-repeat 1px 0;
          margin-left: 0;
        }
        &--twitter{
          background: url('/img/social.jpg') no-repeat -22px 0px;

        }
        &--instagram{
          background: url('/img/social.jpg') no-repeat -44px 0px;
        }
        &--linkedin{
          background: url('/img/social.jpg') no-repeat -66px 0px;
        }
      }
    }
  }
}



/*
 * Flipster
 */

.flipster--loop {

  .flipster__item {
    position: absolute;
  }


  .flipster__item--past-2 { transform: translateX(-100%); }
  .flipster__item--future-2 { transform: translateX(100%); }

  .flipster__item--past-1 { transform: translateX(-50%); }
  .flipster__item--future-1 { transform: translateX(50%); }

}

.flipster--infinite-carousel {

  .flipster__container,
  .flipster__item, {
    transition: all 350ms ease-in-out;
    transition-timing-function: cubic-bezier(.56,.12,.12,.98);
  }

  .flipster__item__content { transition: inherit; }

  .flipster__item {
    position: absolute;
    opacity: 0;
    perspective: 800px;
  }

  .flipster__item--past-2,
  .flipster__item--future-2 {
    opacity: 0.6;
    transition-delay: 90ms;
  }

  .flipster__item--past-1,
  .flipster__item--future-1 {
    opacity: 0.8;
    transition-delay: 60ms;
  }

  .flipster__item--current {
    opacity: 1;
    transition-delay: 0;
  }

  .flipster__item--past .flipster__item__content,
  .flipster__item--future .flipster__item__content { transform: scale(0.4); opacity:0.7 }


  .flipster__item--past-2 .flipster__item__content { transform: translateX(-60%)  scale(0.8); }
  .flipster__item--future-2 .flipster__item__content { transform: translateX(-60%) scale(0.8); }

  .flipster__item--past-1 .flipster__item__content { transform: translateX(-60%) scale(0.8); }
  .flipster__item--future-1 .flipster__item__content { transform: translateX(60%) scale(0.8); }


  .flipster__item--current .flipster__item__content { transform: translateX(0) rotateY(0deg) scale(1); }
}

.flipster:focus{
  outline: none;
}


.controls {
  text-align: center;
  margin-top: 40px;
}


.next {
  display: inline-block;
  width: 41px;
  height: 41px;
  background: url('/img/arrow_right.png');
  cursor: pointer;
}


.prev {
  display: inline-block;
  width: 41px;
  height: 41px;
  margin-right: 20px;
  background: url('/img/arrow_left.png');
  cursor: pointer;
}


@media only screen
and(min-width: 1700px) {

  .manage-team{
    &__right-wrap {
      &__floating-card{
        width: 40%;
        height: 250px;
        margin-left: 10%;
      }
    }
  }

}

@import 'mobile';
@import 'features';
@import 'pricing';

